export { default as User } from './user.svg'
export { default as UserFill } from './user-fill.svg'
export { default as Garage } from './garage.svg'
export { default as GarageFill } from './garage-fill.svg'
export { default as Navigation } from './navigation.svg'
export { default as NavigationFill } from './navigation-fill.svg'
export { default as ToolsFill } from './tools-fill.svg'
export { default as Tools } from './tools.svg'
export { default as Box } from './box.svg'
export { default as BoxReturn } from './box-return.svg'
export { default as BoxFill } from './box-fill.svg'
export { default as Loading } from './loading.svg'
export { default as Logout } from './logout.svg'
export { default as Notification } from './notification.svg'
export { default as Visits } from './visits.svg'
export { default as VisitsFill } from './visits-fill.svg'
export { default as People } from './people.svg'
export { default as Settings } from './settings.svg'
export { default as Global } from './global.svg'
export { default as TrendingUp } from './trending-up.svg'
export { default as CoinEuro } from './coin-euro.svg'
export { default as ArrowTriangleTop } from './arrow-triangle-top.svg'
export { default as Stop } from './stop.svg'
export { default as Minus } from './minus.svg'
export { default as MinusCircle } from './minus-circle.svg'
export { default as PlusCircle } from './plus-circle.svg'
export { default as Cross } from './cross.svg'
export { default as ArrowLeft } from './arrow-left.svg'
export { default as History } from './history.svg'
export { default as Document } from './document.svg'
export { default as Plus } from './plus.svg'
export { default as Check } from './check.svg'
export { default as Search } from './search.svg'
export { default as CompletedFill } from './completed-fill.svg'
export { default as Camera } from './camera.svg'
export { default as ResizeOut } from './resize-out.svg'
export { default as ResizeIn } from './resize-in.svg'
export { default as Delete } from './delete.svg'
export { default as ScrollUp } from './scroll-up.svg'
export { default as QRCode } from './qr-code.svg'
export { default as NewWindow } from './new-window.svg'
export { default as OrderStatus } from './order-status.svg'
export { default as ChevronUp } from './chevron-up.svg'
export { default as ChevronDown } from './chevron-down.svg'
export { default as ChevronLeft } from './chevron-left.svg'
export { default as ChevronRight } from './chevron-right.svg'
export { default as Edit } from './edit.svg'
export { default as Filter } from './filter.svg'
export { default as Swap } from './swap.svg'
export { default as BoxPutIn } from './box-put-in.svg'
export { default as BoxPutOut } from './box-put-out.svg'
export { default as C } from './с-status.svg'
export { default as P } from './p-status.svg'
export { default as E } from './e-status.svg'
export { default as R } from './r-status.svg'
export { default as Copy } from './copy.svg'
export { default as FlashlightOn } from './flashlight-on.svg'
export { default as FlashlightOff } from './flashlight-off.svg'
export { default as Location } from './location.svg'
export { default as GeoPin } from './geo-pin.svg'
export { default as GeoPinFill } from './geo-pin-fill.svg'
export { default as StartLocation } from './start-location.svg'
export { default as FinishLocation } from './finish-location.svg'
export { default as Link } from './link.svg'
export { default as Calendar } from './calendar.svg'
export { default as Sort } from './sort.svg'
export { default as SortAsc } from './sort-asc.svg'
export { default as SortDesc } from './sort-desc.svg'
export { default as WarningCircle } from './warning-circle.svg'
export { default as Info } from './info.svg'
export { default as Manual } from './manual.svg'
export { default as RotateLeft } from './rotate-left.svg'
export { default as ToastSuccess } from './toast-success.svg'
export { default as ToastError } from './toast-error.svg'
export { default as ToastWarning } from './toast-warning.svg'
export { default as ToastInfo } from './toast-info.svg'
export { default as Coupon } from './coupon.svg'
export { default as Google } from './google.svg'
export { default as EditProfile } from './edit-profile.svg'

export { default as CountryFr } from './country-fr.svg'
export { default as CountryEn } from './country-en.svg'
export { default as CountryDe } from './country-de.svg'
export { default as CountryNl } from './country-nl.svg'
