import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'

import { WrappedNodeDefault } from 'src/components/common'
import { ListItem, SwipeAction, Typography } from 'src/components/ui'

import { ParcelsArticleType } from 'src/models'

import { useCurrencyFormat } from 'src/hooks'

import { getParcelsArticlesRightActions, getParcelsArticlesLeftActions, rowGutters, getDeletedStatus } from 'src/utils'

type ParcelsArticlesItemProps = {
  item: ParcelsArticleType
  onSelectCell: (type: string, item: ParcelsArticleType) => void
}

export const ParcelsArticlesItem = ({ item, onSelectCell }: ParcelsArticlesItemProps) => {
  const { t } = useTranslation()
  const { currencyFormatNumber } = useCurrencyFormat()

  const renderContent = (value: string | number | null, span: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary'>{WrappedNodeDefault(value)}</Typography>
      </Col>
    )
  }

  const isDeleted = getDeletedStatus(item)

  const articlesRightActions = getParcelsArticlesRightActions({
    onSelectCell: (type) => onSelectCell(type, item),
    article: item,
    t,
  })

  const articlesLeftActions = getParcelsArticlesLeftActions({
    onSelectCell: (type) => onSelectCell(type, item),
    article: item,
    isDeleted,
    t,
  })

  return (
    <SwipeAction rightActions={articlesRightActions} leftActions={articlesLeftActions}>
      <ListItem size='sm'>
        <Row align='middle' gutter={rowGutters}>
          <Col span={8}>
            <Typography variant='secondary' color={isDeleted ? 'error' : 'default'}>
              {WrappedNodeDefault(item.article)}
            </Typography>
            <Typography variant='secondary' color='gray-6'>
              {WrappedNodeDefault(item.title)}
            </Typography>
          </Col>
          {renderContent(item.parcelId, 4)}
          <Col span={3}>
            <Typography variant='secondary'>{currencyFormatNumber(item.weight)}</Typography>
          </Col>
          {renderContent(item.quantity, 3)}
          {renderContent(item.amount, 6)}
        </Row>
      </ListItem>
    </SwipeAction>
  )
}
