import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

import { PlanMetrics, YourProgressToday, WrappedNodeDefault } from 'src/components/common'
import { Typography, Skeleton } from 'src/components/ui'

import { useTourPlanForTodayFetch } from 'src/bus/tour'

import { useToast } from 'src/hooks'

import { orNull, ternary } from 'src/utils'

type PlanForTodayProps = {
  hasProgress?: boolean
  hasEstimate?: boolean
}

export const PlanForToday = ({ hasProgress = false, hasEstimate = false }: PlanForTodayProps) => {
  const { t } = useTranslation()
  const { planForToday, loadingPlanForTodayFetch } = useTourPlanForTodayFetch()
  const showToast = useToast()

  useEffect(() => {
    if (planForToday?.errors) {
      planForToday.errors.map((error) => {
        showToast.warn({ title: t(`notifications:${error.type}`), body: error.message })
      })
    }
  }, [planForToday?.errors, showToast, t])

  const estimate = hasEstimate
    ? [
        { title: 'news-and-plans:visits', value: planForToday?.metrics?.visits },
        { title: 'news-and-plans:estimated_duration', value: planForToday?.estimation?.duration },
        {
          title: 'news-and-plans:estimated_finish_time',
          value: planForToday?.estimation?.finishTime,
        },
      ]
    : []

  return (
    <div className='px-2'>
      <div className='flex flex-col gap-6 py-2'>
        <div className='flex items-center justify-between'>
          <div className='flex-none basis-40'>
            <Typography className='font-semibold'>{t('plan-for-today:plan_for_today')}</Typography>
          </div>
          <div className='inline-flex items-center justify-center overflow-hidden whitespace-nowrap rounded-2xl bg-blue-gray-8 px-2 py-1'>
            <Typography color='white' className='font-semibold'>
              {t('plan-for-today:sectors')} №&nbsp;
            </Typography>
            {ternary(
              loadingPlanForTodayFetch,
              <Skeleton className='h-5 w-7' />,
              <Typography color='white' truncate='line' className='font-semibold'>
                {WrappedNodeDefault(planForToday?.sectors?.join(', '))}
              </Typography>,
            )}
          </div>
        </div>
      </div>
      {ternary(
        hasEstimate,
        <div className='grid grid-cols-3 rounded-sm border border-gray-3 bg-white'>
          {estimate.map((item) => {
            return (
              <div key={item.title} className='flex h-full w-full flex-col gap-1 border-r px-3 py-2 last:border-0'>
                <Typography variant='secondary' color='gray-7' className='h-full'>
                  {t(item.title)}:
                </Typography>
                {ternary(
                  loadingPlanForTodayFetch,
                  <Skeleton className='h-8' />,
                  <Typography variant='head'>{WrappedNodeDefault(item.value)}</Typography>,
                )}
              </div>
            )
          })}
        </div>,
        <div className='mb-4 mt-2'>
          <PlanMetrics loadingFetch={loadingPlanForTodayFetch} data={planForToday?.metrics} />
        </div>,
      )}
      {orNull(hasProgress, <YourProgressToday data={planForToday?.progress} loadingFetch={loadingPlanForTodayFetch} />)}
    </div>
  )
}
